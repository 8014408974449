@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Orbitron:wght@500;600;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #d63447;
  --secondary: #f57b51;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f57a516e;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.block-title:after {
  position: absolute;
  content: "";
  background: #eee;
  width: 100px;
  height: 2px;
  left: 0;
  bottom: -2px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.block:hover .block-title:after {
  width: 100%;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.pointer {
  cursor: pointer;
}
.width {
  width: 100% !important;
}

.textField {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

textarea {
  resize: none;
}

.container {
  max-width: 1200px;
  margin: 3rem auto 4rem;
  padding: 30px;
}

.title {
  color: #790316;
  font-size: 62px;
  position: relative;
  font-weight: normal;
  text-transform: capitalize;
  font-style: normal;
  text-decoration: none;
  line-height: 62px;
  letter-spacing: -1px;
  margin-bottom: 3rem;
  /* text-align: center; */
}

.title:after {
  position: absolute;
  content: "";
  background: #eee;
  width: 100px;
  height: 2px;
  left: 0;
  bottom: -15px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.cursor {
  cursor: pointer;
}

.container:hover .title:after {
  width: 100%;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

@media screen and (max-width: 480px) {
  .title {
    margin-bottom: 20px;
    font-size: 35px;
  }
  .container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 375px) {
  .title {
    font-size: 28px;
  }
  
}